<template>
  <mosaic-dialog
    :active="active"
    :title="`Are you sure you want to delete ${traineeNounCapitalised()} data?`"
    @update:active="emit('update:active', $event)"
    @close="emit('cancel')"
    @click-outside="emit('cancel')"
  >
    <slot></slot>
    <div class="mt-2">
      <div class="mb-2">
        This will permanently delete {{ traineeNounCapitalised() }} data. Double check that you are happy for this data
        to be removed (and if you're not sure then contact support).
      </div>
      <div>If you're happy to proceed then type "permanently delete" below:</div>
      <mosaic-text-field
        v-model="permanentlyDelete"
        name="perm-delete"
        :placeholder="`Type &quot;permanently delete&quot; to delete ${traineeNounCapitalised()} data`"
        label=""
        no-icon
      />
    </div>

    <template #buttons>
      <v-btn variant="text" ripple color="error" :disabled="!canDelete" @click.prevent="confirmDelete()">Delete</v-btn>
    </template>
  </mosaic-dialog>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

defineProps<{ active: boolean }>();

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'confirm'): void;
  (e: 'update:active', active: boolean): void;
}>();

const permanentlyDelete = ref('');
const canDelete = computed(() => permanentlyDelete.value === 'permanently delete');

function confirmDelete() {
  emit('confirm');
  emit('update:active', false);
}
</script>
