<template>
  <v-card class="mb-4">
    <mosaic-extra-filters-sync-query v-model:show-extra-filters="showExtraFilters" />
    <v-card-text>
      <div v-if="title" class="d-flex align-center flex-wrap">
        <mosaic-card-title>{{ title }}</mosaic-card-title>
        <div v-if="$slots.switches" class="ml-4">
          <slot name="switches"></slot>
        </div>
        <div
          class="flex-grow-1 d-flex align-center row-wrap justify-end"
          style="row-gap: 4px; column-gap: 8px"
          v-if="$slots.actions"
        >
          <slot name="actions"></slot>
        </div>
      </div>

      <div class="d-flex align-top">
        <div
          v-if="$slots.extraFilters"
          class="mt-9 mr-2"
          style="cursor: pointer"
          @click="showExtraFilters = !showExtraFilters"
        >
          <mosaic-icon :color="anyFiltersApplied ? 'primary' : ''" icon="filter" />
          <mosaic-icon :icon="`mdi-chevron-${showExtraFilters ? 'up' : 'down'}`" />
        </div>
        <div class="flex-grow-1">
          <div
            class="d-flex"
            :class="{
              'flex-column': smallScreen,
              'align-center': !smallScreen,
              'vertical-flex-spacing': smallScreen,
              'horizontal-flex-spacing': !smallScreen,
            }"
          >
            <slot />
          </div>
          <div
            v-if="$slots.filters"
            class="d-flex pt-4"
            :class="{
              'flex-column': smallScreen,
              'align-center': !smallScreen,
              'vertical-flex-spacing': smallScreen,
              'horizontal-flex-spacing': !smallScreen,
            }"
          >
            <slot name="filters" />
          </div>

          <div
            v-show="showExtraFilters"
            class="pt-6"
            :class="{
              'd-flex': showExtraFilters,
              'flex-column': smallScreen,
              'align-center': !smallScreen,
              'vertical-flex-spacing': smallScreen,
              'horizontal-flex-spacing': !smallScreen,
            }"
          >
            <slot name="extraFilters" />
          </div>
        </div>
      </div>

      <div v-if="$slots.alert" class="mt-4">
        <slot name="alert"></slot>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import MosaicExtraFiltersSyncQuery from './MosaicExtraFiltersSyncQuery.vue';
import { ref, computed, watch } from 'vue';
const props = defineProps<{
  title?: string;
  filtersApplied?: boolean;
  extraFiltersApplied?: boolean;
}>();
const showExtraFilters = ref(props.extraFiltersApplied ?? false);

watch(
  () => props.extraFiltersApplied,
  x => {
    if (x === true) {
      showExtraFilters.value = true;
    }
  }
);

const anyFiltersApplied = computed(() => props.filtersApplied || props.extraFiltersApplied);
</script>
