<template>
  <div>
    Sorry, can't load
    {{ viewingStudent ? `${objectTypeIsPlural ? 'these' : 'this'} ${traineeNounCapitalised()}'s` : 'your' }}
    {{ objectType }} at the moment. Please check your internet connection,
    <a class="text-link" @click="emit('retry')">retry</a> a couple of times and, if this still doesn't work, then
    <a @click="layoutContainerContactSupport">contact support</a>.
  </div>
</template>

<script setup lang="ts">
import { inject } from 'vue';

defineProps<{
  objectType: string;
  objectTypeIsPlural?: boolean;
  viewingStudent?: boolean;
}>();

const emit = defineEmits<{
  (e: 'retry'): void;
}>();

// Type cast (rather than <>) as this is always provided
const layoutContainerContactSupport = inject('layoutContainerContactSupport') as () => void;
</script>
