<template>
  <v-tooltip location="top">
    <template #activator="{ props }">
      <v-icon class="mr-2 ml-2" :color="color" v-bind="props">mdi-{{ icon }}</v-icon>
    </template>
    <div class="d-flex align-top my-2">
      <div>
        <v-icon class="mr-4" color="white">mdi-{{ icon }}</v-icon>
      </div>
      <div>
        <span class="tooltip-content">{{ tooltip }}</span>
      </div>
    </div>
  </v-tooltip>
</template>

<script>
export default {
  name: 'MosaicInfoIcon',
  props: {
    icon: {
      type: String,
    },
    color: {
      type: String,
    },
    tooltip: {
      required: true,
      type: String,
    },
  },
};
</script>

<style scoped>
.tooltip-content {
  width: 300px !important;
  display: inline-block;
}
</style>
