<template>
  <div>
    <div v-if="label" class="text-caption mb-1">{{ label }}</div>
    <v-radio-group
      :model-value="modelValue"
      @update:model-value="emitChange($event)"
      v-bind="$attrs"
      :class="{ 'mt-0': !!label }"
      :readonly="readonly"
      :color="color || 'black'"
    >
      <v-radio
        v-for="radio in radios"
        :key="radio.value"
        :value="radio.value"
        :label="radio.label"
        :disabled="readonly && modelValue != radio.value"
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  radios: {
    label: string;
    value: number | string;
  }[];
  modelValue?: number | string | boolean | null;
  readonly?: boolean;
  label?: string;
  color?: string;
}>();

const emit = defineEmits(['update:modelValue']);

function emitChange(value: number | string | boolean | null) {
  emit('update:modelValue', value);
}
</script>
