<template>
  <div>
    <div
      v-if="appMessageText"
      id="app-message"
      class="d-flex justify-center px-2 py-2"
      style="width: 100%; background-color: black; position: fixed; color: white; z-index: 999"
    >
      <div>
        {{ appMessageText }}
      </div>
    </div>
    <v-app>
      <layout-container :visible-app-message="visibleAppMessage">
        <!-- ideally this would match the loading state of the underlying page - this should match TutorCohortWrapper.vue -->
        <mosaic-loading-card v-if="showLoading" type="list"></mosaic-loading-card>
        <router-view v-else-if="finishedNavigating" />
      </layout-container>

      <nasbtt-modules-account-link-dialog v-model:active="linkDialog.active" />
    </v-app>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import '@/types/array';
import { useTheme } from 'vuetify';
import moment from 'moment';
import LayoutContainer from '@/components/layout-container/LayoutContainer.vue';
import { useNasbttModulesStore } from '@/stores/nasbtt-modules';
import NasbttModulesAccountLinkDialog from './components/nasbtt-modules/NasbttModulesAccountLinkDialog.vue';
import EventBus from '@/utils/event-bus';
import { useStaffTrainingStore } from './stores/staff-training';
import { renderFriendlyText } from '@/utils/text';
import { formatDateTime } from '@/utils/date';
import { DateTime } from 'luxon';
import { useCohortStore } from '@/stores/cohort';

export default {
  name: 'App',
  components: { LayoutContainer, NasbttModulesAccountLinkDialog },
  data: function () {
    return {
      showLoading: false,
      finishedNavigating: true,
      now: null,
      nowInterval: null,
      pollInterval: null,
    };
  },
  setup() {
    const { nasbttModulesAccountLinkDialog: linkDialog } = useNasbttModulesStore();
    const staffTrainingStore = useStaffTrainingStore();
    // So watchers are registered on app startup
    const cohortStore = useCohortStore();
    return {
      linkDialog,
      theme: useTheme(),
      staffTrainingStore,
      cohortStore,
    };
  },
  computed: {
    ...mapState(['isImpersonating', 'isNavigating', 'appMessages']),
    ...mapGetters(['isMosaicEarlyCareers']),
    appMessageText() {
      if (!this.visibleAppMessage) return;
      return renderFriendlyText(this.visibleAppMessage.message, {
        startPollingAt: formatDateTime(this.visibleAppMessage.startPollingAt),
        stopShowingAt: formatDateTime(this.visibleAppMessage.stopShowingAt),
      });
    },
    shouldPollForAppMessage() {
      if (!this.visibleAppMessage || !this.now) return false;

      return (
        DateTime.fromISO(this.visibleAppMessage.startPollingAt) < this.now &&
        this.now < DateTime.fromISO(this.visibleAppMessage.stopShowingAt)
      );
    },
    visibleAppMessage() {
      const messages = this.appMessages.filter(
        m => DateTime.fromISO(m.startShowingAt) < this.now && this.now < DateTime.fromISO(m.stopShowingAt)
      );
      return messages.length > 0 ? messages[0] : null;
    },
  },
  methods: {
    startNowInterval() {
      if (!this.nowInterval) {
        this.nowInterval = setInterval(() => (this.now = DateTime.now()), 1000);
      }
    },
  },
  watch: {
    isNavigating(x) {
      if (x) {
        this.finishedNavigating = false;
        // Only show loading if the navigation takes more than 100ms to avoid flickering
        setTimeout(() => {
          if (!this.finishedNavigating) {
            this.showLoading = true;
            this.finishedNavigating = true;
          }
        }, 100);
      } else {
        this.finishedNavigating = true;
        this.showLoading = false;
      }
    },
    isMosaicEarlyCareers(x) {
      this.theme.global.name.value = x ? 'earlyCareers' : 'ITT';
    },
    shouldPollForAppMessage(x) {
      if (x) {
        if (!this.pollInterval) {
          this.pollInterval = setInterval(() => this.$store.dispatch('loadVersionsAndAppMessages'), 1000 * 60);
        }
      } else {
        if (this.pollInterval) {
          clearInterval(this.pollInterval);
          this.pollInterval = null;
        }
      }
    },
    appMessages(x) {
      if (x && x.length > 0) {
        this.startNowInterval();
      } else {
        clearInterval(this.nowInterval);
        this.nowInterval = null;
      }
    },
  },
  created() {
    moment.locale('en-gb');
    this.$store.dispatch('loadVersionsAndAppMessages');
    if (this.visibleAppMessage) startNowInterval();
  },
  mounted() {
    EventBus.on('dispatch-load-staff-training', ({ staffId, force }) => {
      this.staffTrainingStore.actions.loadStaffTraining(staffId, force);
    });
  },
  unmounted() {
    if (this.nowInterval) {
      clearInterval(this.nowInterval);
    }

    if (this.pollInterval) {
      clearInterval(this.pollInterval);
    }
  },
};
</script>

<style>
:root {
  --light-grey: #eeeeee;
}

.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.flex-grow-1-child > * {
  flex-grow: 1;
}
.flex-grow-1-no-overflow {
  flex-grow: 1;
  min-width: 0;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-grow-0 {
  flex-grow: 0;
}
.min-w-0 {
  min-width: 0;
}
.fb-50 {
  flex-basis: 50%;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-leave-to {
  transform: translateX(100px);
  opacity: 0;
}

.vertical-flex-spacing {
  column-gap: 16px;
  row-gap: 16px;
}

.horizontal-flex-spacing {
  column-gap: 16px;
  row-gap: 16px;
}

.vertical-flex-spacing-small {
  column-gap: 8px;
  row-gap: 8px;
}

.horizontal-flex-spacing-small {
  column-gap: 8px;
  row-gap: 8px;
}

/* Make v-expansion-panel text colors the same as v-card */
.theme--light.v-expansion-panels .v-expansion-panel {
  color: rgba(0, 0, 0, 0.6) !important;
}

/* fixes for wobbly icons when spinning */
.fa-spin {
  text-indent: -0.000001em;
  line-height: 0.6em;
}
.fa-spin.fa-lg {
  line-height: 0.7em;
}
.fa-spin.fa-2x {
  line-height: 0.8em;
}
.fa-spin.fa-3x,
.fa-spin.fa-4x,
.fa-spin.fa-5x {
  line-height: 1em;
}

.text-h7 {
  font-weight: 400;
  font-size: 1.125rem;
}

.text-h8 {
  font-weight: 500;
  font-size: 1rem;
}

.text-color-default {
  color: rgba(0, 0, 0, 0.6);
}

.text-link {
  color: rgb(var(--v-theme-primary));
  cursor: pointer;
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

/* Vuetify 3 appears to  make <ul> alignment too far left */
ul {
  padding-left: 16px !important;
}
/* Vuetify 3 appears to remove bottom spacing from <p> */
p {
  margin-bottom: 8px !important;
}
</style>
