<template>
  <mosaic-snackbar
    color="success"
    :message="message"
    :model-value="modelValue"
    @update:model-value="emit('update:modelValue', $event)"
    :contained="contained"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    objectType: string;
    action?: string;
    contained?: boolean;
  }>(),
  {
    action: 'saved',
  }
);

const message = computed(() => `${props.objectType} ${props.action}`);

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();
</script>
