<template>
  <div>
    <mosaic-error-snackbar v-model="moveItemError" :action="`move this ${objectType}`" />
    <mosaic-list :items="sortedItems" :empty-text="emptyText(objectTypePluralised)">
      <template #item="{ item, first, last }">
        <mosaic-list-item
          :key="item.id"
          :title="item.title"
          :subtitle="item.subtitle"
          :icon="item.icon || icon"
          :to="item.clickTo"
        >
          <template #information v-if="$slots.information">
            <slot name="information" v-bind="{ item }"></slot>
          </template>

          <template v-if="!hideActions" #actions>
            <slot name="actions" v-bind="{ item }"></slot>
            <template v-if="moveItem">
              <mosaic-icon-btn
                icon="mdi-arrow-up"
                :tooltip="moveDisabledMessage || `Move ${objectType} up`"
                :hidden="first || item.hideMove"
                :disabled="moveItemProcessing"
                @click.prevent="moveItemUp(item)"
              />
              <mosaic-icon-btn
                icon="mdi-arrow-down"
                :tooltip="moveDisabledMessage || `Move ${objectType} down`"
                :hidden="modifiedLast(item, last) || item.hideMove"
                :disabled="moveItemProcessing"
                @click.prevent="moveItemDown(item)"
              />
            </template>
            <mosaic-icon-btn
              icon="mdi-pencil"
              :tooltip="`${editAction} ${objectType}`"
              :disabled="item.disableEdit"
              :disabled-tooltip="item.editDisabledTooltip || ''"
              @click.prevent="clickEditItem(item)"
            />
            <mosaic-delete-icon-btn
              :object-type="objectType"
              :disabled="item.disableDelete"
              :disabled-tooltip="item.deleteDisabledTooltip || ''"
              @click.prevent="deleteItem(item)"
            />
          </template>
        </mosaic-list-item>
      </template>
    </mosaic-list>

    <mosaic-save-dialog
      v-model:active="editItemDialog.active"
      :title="`${editAction} ${objectType}`"
      :object-type="objectType"
      :save="saveEditItem"
      :can-save="canEditItem"
      @save="internalTriggerBackgroundLoad = true"
    >
      <template #default="{ onKeyupEnter }">
        <slot name="edit-item-fields" :on-keyup-enter="onKeyupEnter"></slot>
      </template>
    </mosaic-save-dialog>

    <mosaic-delete-dialog
      v-model:active="deleteItemDialog.active"
      :url="deleteItemDialog.url"
      :object-type="objectType"
      :object-name="deleteItemDialog.title"
      @delete="internalTriggerBackgroundLoad = true"
    />
  </div>
</template>

<script setup lang="ts" generic="T extends Item">
import { computed, ref, watch } from 'vue';
import { pluralise } from '@/utils/text';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import type { Item } from './list-item-helpers';

/* This is the legacy version, as it accepts the #edit-item-fields rather than a dialog. We should be using a dialog because the dialog
should be re-used on the item page once you've clicked into it. Potentially this component needs to support either way, but currently
the only use case for this is the AdminProfessionalResourcesPage and it's probably fine for that to supply a dialog */

/*
The MosaicOrderedList is for lists with a limited number of items (no filtering or pagination required) and items that are ordered
Longer lists that don't have explicit ordering should use the MosaicFilterListPage.
*/

const props = withDefaults(
  defineProps<{
    objectType: string;
    items: T[];
    icon: string;
    moveItem?: (direction: 'up' | 'down', item: T) => Promise<void>;
    moveDisabledMessage?: string;
    triggerBackgroundLoad?: boolean;
    editItemDialogOpened: (item: T) => void;
    editItem: (id: number) => Promise<void>;
    deleteUrlStem: string;
    canEditItem: boolean;
    editAction?: string;
    hideAdd: boolean;
    hideActions?: boolean;
  }>(),
  {
    editAction: 'Rename',
  }
);

const emptyText = (objectTypePluralised: string) =>
  `There are no ${objectTypePluralised}.${!props.hideAdd ? ' Add one below.' : ''}`;
const emit = defineEmits<{
  (e: 'update:triggerBackgroundLoad', triggerBackgroundLoad: boolean): void;
  (e: 'afterUpdate'): void;
}>();

const sortedItems = computed(() => props.items.sortBy('order'));

const objectTypePluralised = computed(() => pluralise(props.objectType));

const internalTriggerBackgroundLoad = ref(false);
watch(
  () => props.triggerBackgroundLoad,
  x => (internalTriggerBackgroundLoad.value = !!x)
);
watch(internalTriggerBackgroundLoad, x => {
  emit('update:triggerBackgroundLoad', x);
});

// Move item up and down
async function internalMoveItem(direction: 'up' | 'down', item: T) {
  if (!props.moveItem) return;
  await props.moveItem(direction, item);
  internalTriggerBackgroundLoad.value = true;
  emit('afterUpdate');
}

const { action: move, processing: moveItemProcessing, error: moveItemError } = withProcessingAndError(internalMoveItem);

function moveItemUp(item: T) {
  move('up', item);
}
function moveItemDown(item: T) {
  move('down', item);
}

function modifiedLast(item: T, last: boolean) {
  if ('last' in item) {
    return item.last;
  }
  return last;
}

const editItemDialog = ref({
  active: false,
  id: -1,
});

function clickEditItem(item: T) {
  editItemDialog.value = {
    active: true,
    id: item.id,
  };
  props.editItemDialogOpened(item);
}

async function saveEditItem() {
  await props.editItem(editItemDialog.value.id);
  internalTriggerBackgroundLoad.value = true;
  emit('afterUpdate');
}

const deleteItemDialog = ref({ active: false, title: '', url: '' });
function deleteItem(item: T) {
  deleteItemDialog.value = {
    active: true,
    title: item.title,
    url: `${props.deleteUrlStem}/${item.id}`,
  };
}
</script>

<style scoped>
.full-width {
  flex-basis: 100%;
  padding-right: 8px;
}
</style>
