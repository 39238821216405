<template>
  <div>
    <v-menu
      v-if="!readonly"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      :disabled="disabled"
      min-width="auto"
      offset="-16px"
    >
      <template #activator="{ props: menuProps }">
        <v-text-field
          v-bind="menuProps"
          v-model="formattedTime"
          :label="label"
          :hide-details="hideDetails"
          :density="dense ? 'compact' : 'default'"
          readonly
          :style="{ 'min-width': mode == 'time' ? '120px' : '90px', 'max-width': '150px' }"
          :rules="rules"
        >
          <template #prepend>
            <mosaic-icon icon="clock-outline" />
          </template>
        </v-text-field>
      </template>

      <v-sheet>
        <div class="d-flex pa-4 align-center justify-center">
          <div style="width: 70px" class="pr-4">
            <v-text-field
              v-model="hoursInput"
              label="Hours"
              type="number"
              min="0"
              max="12"
              hide-details
              density="compact"
            />
          </div>
          <div style="width: 70px" class="pr-4">
            <v-text-field
              v-model="minutesInput"
              label="Minutes"
              type="number"
              min="0"
              max="59"
              hide-details
              density="compact"
            />
          </div>
          <v-btn-toggle variant="outlined" divided v-model="amPm" mandatory v-if="mode === 'time'">
            <v-btn value="AM">AM</v-btn>
            <v-btn value="PM">PM</v-btn>
          </v-btn-toggle>
        </div>
      </v-sheet>
    </v-menu>

    <!-- mb-4 will need to change if hide-details etc. are supplied -->
    <div v-else class="d-flex align-center mb-4">
      <mosaic-icon class="mt-5" icon="clock-outline" />
      <div class="pl-4">
        <div class="text-caption">{{ label }}</div>
        <div class="text-body-1">{{ formattedTime }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { formatTime } from '@/utils/time';
import type { Rule } from '@/utils/validations';
import { validationsPass } from '@/utils/validations';
import { watchEffect } from 'vue';
import { computed } from 'vue';
import { ref } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue?: string; // H:mm
    label: string;
    disabled?: boolean;
    hideDetails?: boolean;
    dense?: boolean;
    readonly?: boolean;
    mode?: 'time' | 'duration';
    allowZeroDuration?: boolean;
  }>(),
  { mode: 'time', allowZeroDuration: false }
);

const emit = defineEmits<{
  'update:modelValue': [value: string | null];
  valid: [valid: boolean];
}>();

const menu = ref(false);

const [HH, mm] = props.modelValue?.split(':') || ['0', '0'];
const HHInt = parseInt(HH);
const isAfternoon = HHInt >= 12;
const defaultHours = (isAfternoon ? (HHInt == 12 ? 12 : HHInt - 12) : HHInt).toString();

const hoursInput = ref(defaultHours);
const hours = computed(() => {
  const h = parseInt(hoursInput.value);
  if (isNaN(h) || h < 0) return 0;
  if (props.mode == 'time' && h > 12) return 12;
  return h;
});

const minutesInput = ref(mm);
const minutes = computed(() => {
  const m = parseInt(minutesInput.value);
  if (isNaN(m) || m < 0) return 0;
  if (m > 59) return 59;
  return m;
});

const amPm = ref<'AM' | 'PM'>(isAfternoon ? 'PM' : 'AM');

const formattedTime = computed(
  () => `${formatTime(hours.value, minutes.value)}${props.mode == 'time' ? ` ${amPm.value}` : ''}`
);

const rules = computed<Rule[]>(() => {
  if (props.mode == 'time') return [];
  if (props.allowZeroDuration) return [];
  return [(v: string | number) => v != '0:00' || `${props.label} must be greater than 0`];
});
watchEffect(() => emit('valid', validationsPass(rules.value, formattedTime.value)));

const output = computed(() => {
  if (props.mode == 'time') {
    const h = hours.value == 12 ? 0 : hours.value;
    return formatTime(h + (amPm.value === 'PM' ? 12 : 0), minutes.value);
  } else {
    return formatTime(hours.value, minutes.value);
  }
});
watchEffect(() => emit('update:modelValue', output.value));
</script>
