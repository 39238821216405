import store from '../store/index';

function isAshton() {
  return store.state.selectedInstitution.name == 'Ashton on Mersey SCITT';
}

function proficiencies() {
  return isAshton();
}

function rolesBasedPermissions() {
  return !isProduction();
}

export default {
  proficiencies,
  rolesBasedPermissions,
};

function isProduction() {
  return window.ndt_config && window.ndt_config.environment == 'production';
}
