<template>
  <div class="d-flex align-center">
    <div>
      <v-checkbox
        :readonly="readonly"
        :disabled="disabled || readonly"
        v-bind="$attrs"
        color="primary"
        :prepend-icon="prependIcon"
        :model-value="modelValue"
        :label="label"
        :name="name"
        :rules="allRules"
        :class="prependIcon ? '' : noIcon ? '' : 'pl-8'"
        :hide-details="hideDetails || helpText"
        :density="dense ? 'compact' : 'default'"
        @update:model-value="$emit('update:modelValue', !!$event)"
      >
        <template v-if="!label" #label><slot name="label" /></template>
      </v-checkbox>
    </div>
    <div v-if="helpText" class="pl-2">
      <mosaic-help>{{ helpText }}</mosaic-help>
    </div>
    <!-- TODO: implement readonly state or just use the disabled state? -->
  </div>
</template>

<script>
export default {
  name: 'MosaicCheckbox',
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      required: false,
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
    helpText: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  computed: {
    allRules() {
      return this.required ? [v => !!v || 'Box must be checked', ...this.rules] : this.rules;
    },
  },
};
</script>
