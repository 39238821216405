<template>
  <div>
    <div class="d-flex">
      <slot name="top-line" />
      <div class="flex-grow-1" />

      <div class="d-flex" style="cursor: pointer" @click="open = !open">
        <div v-if="!smallScreen" class="pr-2"><em>See more</em></div>
        <v-icon>{{ `mdi-chevron-${open ? 'up' : 'down'}` }}</v-icon>
      </div>
    </div>
    <div v-if="open">
      <slot name="body" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const open = ref(true);
</script>
