import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import { installApiPlugin } from './application/api';
import { registerComponents } from '@/components/library/global-registration';
import { globalMixin } from '@/mixins/global-mixins';
import { createPinia } from 'pinia';
import '@/types/luxon';
import vuetify from './application/vuetify';
// Polyfills for stable ES and Web Standard features
import 'core-js/stable';

// Register Quill modules
import './application/quill';
import { registerSentry } from './application/sentry';

const version = window.ndt_config.app_version;
store.commit('updateCurrentAppVersion', version);

const app = createApp(App);

app.use(router);
app.use(store);

app.use(installApiPlugin);

registerSentry(app, router);

const pinia = createPinia();
app.use(pinia);

app.use(vuetify);

registerComponents(app);
app.mixin(globalMixin);

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', () => {
    const el = document.body.appendChild(document.createElement('div'));
    app.mount(el);
  });
} else {
  const el = document.body.appendChild(document.createElement('div'));
  app.mount(el);
}
