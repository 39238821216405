<template>
  <span>
    <v-tooltip location="top">
      <template #activator="{ props }">
        <span v-bind="props">
          <v-chip :color="completed ? 'primary' : 'accent'">
            {{ initialiseText(name) }}
          </v-chip>
        </span>
      </template>
      <span v-if="tooltip">{{ tooltip }}</span>
      <span v-else>{{ name }}: {{ completed ? verb : 'Not ' + verb }}</span>
    </v-tooltip>
  </span>
</template>

<script>
import { initialiseText } from '@/utils/text';

export default {
  name: 'MosaicCompletedChip',
  props: {
    completed: {
      required: true,
      type: Boolean,
    },
    name: {
      required: true,
      type: String,
    },
    verb: {
      default: 'Completed',
      type: String,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  methods: {
    initialiseText,
  },
};
</script>
