<template>
  <div class="mb-2" style="min-width: 200px">
    <mosaic-select
      v-if="!readonly"
      :label="showLabel ? label : ''"
      name="c"
      hide-details
      dense
      prepend-icon="mdi-pencil-box-outline"
      :model-value="modelValue"
      :items="items"
      item-value="id"
      @update:model-value="$emit('update:modelValue', $event)"
    >
      <template #item="{ props, item }">
        <v-list-item v-bind="props" title="">
          <div :style="`color: ${item.raw.color}`">{{ item.raw.descriptor.toUpperCase() }}</div>
        </v-list-item>
      </template>
      <template #selection="{ item }">
        <div :style="`color: ${item.raw.color}`">{{ item.raw.descriptor.toUpperCase() }}</div>
      </template>
    </mosaic-select>
    <div v-else :style="{ marginTop: '-12px' }">
      <div class="d-flex align-center" data-test-name="judgement-select">
        <v-icon class="mt-5">mdi-pencil-box-outline</v-icon>
        <div class="pl-4">
          <div>{{ label }}</div>
          <div v-if="judgementDescriptor">
            <v-chip :color="judgementDescriptor.color">{{ judgementDescriptor.descriptor }}</v-chip>
          </div>
          <div v-else class="text-body-1">Not recorded</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// I don't think this component should have the margin at the top and use dense, but need to look at review points UI before changing
// Also review points should probably use the readonly view here

export default {
  name: 'MosaicJudgementSelect',
  props: {
    modelValue: {
      type: [Number, null],
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
    // This is a hack around review points - should look at the UI there - generally I think we should always be showing the label
    alwaysShowLabel: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Assessment',
    },
  },
  emits: ['update:modelValue'],
  computed: {
    showLabel() {
      return this.alwaysShowLabel || !this.modelValue;
    },
    judgementDescriptor() {
      return this.items.find(i => i.id === this.modelValue);
    },
  },
};
</script>

<style>
/* .judgement-select .v-select__selections input {
  display: none;
} */
</style>
