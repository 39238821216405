<template>
  <mosaic-select
    :readonly="readonly"
    name="judgement-set-select"
    label="Descriptors"
    prepend-icon="mdi-pencil-box-multiple-outline"
    v-bind="$attrs"
    item-value="id"
    item-title="name"
    :model-value="modelValue"
    :class="{ 'judgement-set-select': !!modelValue }"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <template #item="{ item, props }">
      <v-list-item v-bind="props" title="">
        <span v-for="(descriptor, index) in item.raw.institutionJudgementDescriptors" :key="descriptor.id"
          ><span v-if="index != 0" class="mx-1">/</span>
          <span :style="`color: ${descriptor.color}`" class="text-body-2"
            >{{ descriptor.descriptor.toUpperCase() }}
          </span></span
        >
      </v-list-item>
    </template>
    <template #selection="{ item }">
      <span v-for="(descriptor, index) in item.raw.institutionJudgementDescriptors" :key="descriptor.id"
        ><span v-if="index != 0" class="mx-1">/</span>
        <span :style="`color: ${descriptor.color}`" class="text-body-2"
          >{{ descriptor.descriptor.toUpperCase() }}
        </span></span
      >
    </template>
  </mosaic-select>
</template>

<script>
export default {
  name: 'MosaicJudgementSetSelect',
  props: {
    // eslint-disable-next-line vue/require-prop-types
    modelValue: {
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
};
</script>

<style>
.judgement-set-select .v-select__selections input {
  display: none;
}
</style>
