<template>
  <div>
    <v-text-field
      v-model="filter"
      :label="`Filter by  name or email`"
      prepend-icon="mdi-magnify"
      style="width: 400px"
      hide-details
    />
  </div>
</template>

<script>
import { syncQueryParamsMixin } from '@/mixins/query-mixins';
import { useQueryStore } from '@/stores/query';

export default {
  name: 'MosaicNameEmailFilter',
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [
    syncQueryParamsMixin({
      filter: { query: 'name' },
    }),
  ],
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data: function () {
    return {
      filter: this.modelValue,
    };
  },
  watch: {
    filter(x) {
      this.$emit('update:modelValue', x);
    },
  },
};
</script>
