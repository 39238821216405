<template>
  <v-tooltip location="top">
    <template #activator="{ props }">
      <v-chip :color="color" v-bind="props"
        ><v-icon class="mr-1">{{ icon }}</v-icon
        ><slot></slot
      ></v-chip>
    </template>
    {{ tooltip }}
  </v-tooltip>
</template>

<script>
export default {
  name: 'MosaicIconBadge',
  props: {
    icon: {
      type: String,
    },
    color: {
      type: String,
    },
    tooltip: {
      required: true,
      type: String,
    },
  },
};
</script>
