<template>
  <div class="d-flex justify-end pt-2">
    <!-- TODO: consider small screens -->
    <div class="flex-grow-1 d-flex align-center horizontal-flex-spacing flex-wrap" v-if="slots.filters">
      <mosaic-icon icon="filter" class="pt-1" />
      <slot name="filters" />
    </div>
    <div v-if="action || actions" class="pb-1 pr-1">
      <mosaic-btn v-if="action" :icon="action.icon" @click="action.click">{{ action.text }}</mosaic-btn>
      <mosaic-btn-with-menu v-else-if="actions" :menu-items="actions.items" :icon="actions.icon" :text="actions.text" />
    </div>
  </div>
  <div :class="{ 'pt-4': items.length === 0 }"></div>
  <mosaic-list
    :items="paginatedList"
    class="pb-0"
    :class="{ 'pt-0': !hasFiltersOrActions || paginatedList.length === 0 }"
    :empty-text="emptyText"
  >
    <template #item="{ item }">
      <slot name="item" :item="item" />
    </template>
  </mosaic-list>
  <mosaic-pagination
    v-model="currentPage"
    v-model:page-size="pageSize"
    :total="paginationTotal"
    :mosaic-key="mosaicKey"
  />
</template>

<script setup lang="ts" generic="T">
import type { MenuItem, MenuItemContent } from '@/components/library/buttons/mosaic-button-with-menu';
import { computed, toRef, useSlots } from 'vue';
import { paginateList } from '../pagination/pagination';

// To be used when a tab item (from MosaicTabs) contains a list that requires filters and/or actions

const props = defineProps<{
  items: T[];
  // This is required as it's highly likely a page will have multiple tabs with a list
  mosaicKey: string;
  emptyText?: string;
  action?: MenuItemContent;
  actions?: {
    icon: string;
    text: string;
    items: MenuItem[];
  };
}>();

if (props.action && props.actions) {
  throw 'Cannot supply MosaicTabItemList with both action and actions props';
}

const slots = useSlots();
const hasFiltersOrActions = computed(() => !!slots.filters || props.action || props.actions);

const { paginatedList, currentPage, pageSize, paginationTotal } = paginateList(toRef(props, 'items'));
</script>
