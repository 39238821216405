<template>
  <div>
    <div v-if="targets.length === 0" class="pa-4 pl-6">
      <slot />
    </div>
    <mosaic-list v-else two-line :items="paginatedTargets">
      <template #item="{ item: target }">
        <target-list-item
          :click-target="clickTarget"
          :target="target"
          :target-click-to="targetClickTo"
        ></target-list-item>
      </template>
    </mosaic-list>
    <mosaic-pagination
      :sync-to-query="syncToQuery"
      :mosaic-key="'activeTargets'"
      v-model="currentPage"
      v-model:page-size="pageSize"
      :total="paginationTotal"
      :page-sizes-options="pageSizesOptions"
      :compact="true"
    />
  </div>
</template>

<script setup lang="ts">
import TargetListItem from '@/components/TargetListItem.vue';
import { paginateList } from './library/pagination/pagination';
import { ref, computed } from 'vue';
import type { TargetResponse } from '@/types/responses';

interface ClickToObject {
  name: string;
  params: {
    studentId: number;
    targetId: number;
  };
}
// Setup
const props = defineProps<{
  targets: TargetResponse[];
  targetClickTo: (targetId: number) => ClickToObject;
  clickTarget: () => void;
  pageSizesOptions: number[];
}>();

const syncToQuery = ref(false);
const activeTargets = computed(() => props.targets);

const { paginatedList: paginatedTargets, currentPage, pageSize, paginationTotal } = paginateList(activeTargets, 3);
</script>
