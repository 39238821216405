<template>
  <div v-if="readonly" class="d-flex align-center mb-4" :data-test-name="name">
    <div class="pl-6"></div>
    <div class="pl-4">
      <div class="text-caption pb-1">{{ label }}</div>
      <div style="height: 32px; width: 64px; border: 2px solid black" :style="{ backgroundColor: modelValue }"></div>
    </div>
  </div>
  <div v-else class="d-flex align-top mb-4" :data-test-name="name">
    <div class="pl-6"></div>
    <div class="pl-2">
      <div class="text-caption pb-1">{{ label }}</div>
      <v-menu v-model="menu" :close-on-content-click="false" min-width="auto">
        <template #activator="{ props: menuProps }">
          <div
            style="height: 32px; width: 64px; border: 2px solid black"
            :style="{ backgroundColor: modelValue }"
            v-bind="menuProps"
          ></div>
        </template>

        <v-sheet>
          <div class="pa-1">
            <v-color-picker
              elevation="0"
              show-swatches
              @update:model-value="emit('update:modelValue', $event)"
              :modes="['rgb', 'hex']"
            ></v-color-picker>
          </div>
          <div class="d-flex justify-end pa-1">
            <v-btn variant="text" color="error" @click.prevent="cancel()">Cancel</v-btn>
            <v-btn variant="text" color="primary" @click.prevent="ok()">OK</v-btn>
          </div>
        </v-sheet>
      </v-menu>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const props = defineProps<{
  modelValue: string;
  label: string;
  name: string;
  readonly?: boolean;
}>();

const originalColor = ref('#FFFFFF');

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const menu = ref(false);

function ok() {
  menu.value = false;
  originalColor.value = props.modelValue;
}

function cancel() {
  menu.value = false;
  emit('update:modelValue', originalColor.value);
}
</script>
