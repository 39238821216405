<template>
  <mosaic-disabled-tooltip v-if="disabledTooltip" :disabled="disabled" :tooltip="disabledTooltip">
    <template #default="{ disabled: d }">
      <v-btn ripple v-bind="$attrs" :disabled="d">
        <mosaic-icon v-if="icon" class="pr-2" :icon="icon" />
        <slot></slot>
      </v-btn>
    </template>
  </mosaic-disabled-tooltip>
  <v-btn ripple v-bind="$attrs" :disabled="disabled" :loading="loading" v-else>
    <mosaic-icon v-if="icon" class="pr-2" :icon="icon" />
    <slot></slot>
    <template #loader v-if="slots.loader">
      <slot name="loader"></slot>
    </template>
  </v-btn>
</template>

<script setup lang="ts">
import { useSlots } from 'vue';

defineProps<{
  icon?: string;
  disabled?: boolean;
  disabledTooltip?: string;
  loading?: boolean;
}>();

const slots = useSlots();
</script>
