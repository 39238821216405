<template>
  <mosaic-loading-and-error-cards
    v-model:trigger-background-load="triggerBackgroundLoad"
    :object-type="objectTypePluralised"
    :load="load"
    loading-type="list"
  >
    <mosaic-card>
      <mosaic-card-title
        >{{ titleInternal }}

        <template #chip>
          <slot name="title-chip"></slot>
        </template>

        <template #actions>
          <slot name="title-actions"></slot>
          <mosaic-btn icon="mdi-plus" @click="addDialogActive = true">{{ objectType }}</mosaic-btn>
        </template>
      </mosaic-card-title>

      <div v-if="$slots.subtitle" class="mt-3">
        <slot name="subtitle"></slot>
      </div>
    </mosaic-card>
    <mosaic-card class="mt-4">
      <legacy-mosaic-ordered-list
        :items="items"
        :hide-add="hideAdd"
        :object-type="objectType"
        :icon="icon"
        :move-item="moveItem"
        :move-disabled-message="moveDisabledMessage"
        :trigger-background-load="triggerBackgroundLoad"
        edit-action="Edit"
        :edit-item="editItem"
        :edit-item-dialog-opened="editItemDialogOpened"
        :delete-url-stem="deleteUrlStem"
        :can-edit-item="canEditItem"
        @update:trigger-background-load="triggerBackgroundLoad = $event"
        @after-update="afterUpdate()"
      >
        <template #information="{ item }">
          <mosaic-warning-icon v-if="item.warningMessage" :tooltip="item.warningMessage" />
          <slot name="information" v-bind="{ item }"></slot>
        </template>
        <template #actions="{ item }">
          <slot name="actions" v-bind="{ item }"></slot>
        </template>

        <template #edit-item-fields="{ onKeyupEnter }">
          <slot name="edit-item-fields" :on-keyup-enter="onKeyupEnter"></slot>
        </template>
      </legacy-mosaic-ordered-list>
      <mosaic-save-dialog
        v-model:active="addDialogActive"
        :title="`Add ${addArticle(objectType)}`"
        :object-type="objectType"
        :save="addItem"
        :can-save="canAddItem"
        @save="triggerBackgroundLoad = true"
      >
        <template #default="{ onKeyupEnter }">
          <slot name="add-item-fields" :on-keyup-enter="onKeyupEnter"></slot>
        </template>
      </mosaic-save-dialog>
    </mosaic-card>
  </mosaic-loading-and-error-cards>
</template>

<script setup lang="ts" generic="T extends Item">
import { computed, ref } from 'vue';
import { pluralise, addArticle } from '@/utils/text';
import type { Item } from '../mosaic-list/list-item-helpers';

/*
The MosaicDialogCreationListPage is for lists with a limited number of ordered items
(no filtering or pagination required) but require more complicated creation via a dialog
*/

const props = defineProps<{
  objectType: string;
  title?: string;
  load: () => Promise<void>;
  items: T[];
  icon: string;
  hideAdd: boolean;
  canAddItem: boolean;
  canEditItem: boolean;
  addItem: () => Promise<void>;
  editItemDialogOpened: (item: T) => void;
  editItem: (id: number) => Promise<void>;
  moveItem?: (direction: 'up' | 'down', item: T) => Promise<void>;
  moveDisabledMessage?: string;
  deleteUrlStem: string;
}>();

const emit = defineEmits<{
  (e: 'afterUpdate'): void;
  (e: 'update:addDialogActive', addDialogActive: boolean): void;
  (e: 'update:editDialogActive', editDialogActive: boolean): void;
}>();

function afterUpdate() {
  emit('afterUpdate');
}
const triggerBackgroundLoad = ref(false);

const objectTypePluralised = computed(() => pluralise(props.objectType));
const titleInternal = computed(() => props.title || objectTypePluralised.value);

const addDialogActive = ref(false);
</script>
