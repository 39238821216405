<template>
  <mosaic-snackbar
    color="error"
    :message="message"
    :model-value="Boolean(modelValue)"
    :contained="contained"
    @update:model-value="emit('update:modelValue', $event)"
  />
</template>

<script setup lang="ts">
import { isString } from 'lodash';
import { computed } from 'vue';

const props = defineProps<{
  modelValue: boolean | string;
  action?: string;
  message?: string;
  contained?: boolean;
}>();

if (!props.action && !props.message && props.message != '')
  throw 'One of action or message should be set for MosaicErrorSnackbar';

const message = computed(() => {
  if (isString(props.modelValue)) return props.modelValue;
  return props.message || `Sorry, cannot ${props.action} at the moment`;
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();
</script>
