<template>
  <div class="d-flex mb-4">
    <div class="pr-4 mt-6" v-if="!noIcon">
      <mosaic-icon :icon="prependIcon" />
    </div>
    <div class="flex-grow-1">
      <div class="pb-1 text-color-default">{{ label }}</div>
      <file-upload
        :files="modelValue"
        :readonly="readonly"
        :min-files="minFiles"
        :max-files="maxFiles"
        :presign-url="presignUrl"
        :get-file-url-prefix="getFileUrlPrefix"
        :can-only-add-files-disabled-tooltip="canOnlyAddFilesDisabledTooltip"
        :allowed-content-types="allowedContentTypes"
        @file-added="fileAdded"
        @file-removed="fileRemoved"
        @uploading="emit('uploading', $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import FileUpload, { type FileDetails } from '@/components/mosaic-content-layout/FileUpload.vue';

const props = withDefaults(
  defineProps<{
    modelValue: FileDetails[];
    label: string;
    name: string;
    readonly?: boolean;
    minFiles: number;
    maxFiles: number;
    presignUrl: string;
    getFileUrlPrefix: string;
    prependIcon?: string;
    noIcon?: boolean;
    canOnlyAddFilesDisabledTooltip?: string;
    allowedContentTypes?: string[];
  }>(),
  {
    prependIcon: 'file-upload',
    readonly: false,
  }
);

const emit = defineEmits<{
  (e: 'update:modelValue', files: FileDetails[]): void;
  (e: 'uploading', uploading: boolean): void;
}>();

function fileAdded(file: FileDetails) {
  emit('update:modelValue', [...props.modelValue, file]);
}

function fileRemoved(file: FileDetails) {
  emit(
    'update:modelValue',
    props.modelValue.filter(f => f.fileId != file.fileId)
  );
}
</script>
