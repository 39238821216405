<template>
  <mosaic-icon-btn
    v-bind="$attrs"
    icon="mdi-close"
    :tooltip="objectType ? `Close ${props.objectType}` : 'Close'"
    v-on="$attrs"
  />
</template>

<script setup lang="ts">
const props = defineProps<{
  objectType?: string;
}>();
</script>
