export type SidebarItemConfig<T extends object> = T | SidebarItemWithSubItemsConfig<T>;

export interface SidebarItemWithSubItemsConfig<T> {
  icon: string;
  title: string;
  children: T[];
  pageNameSuffix?: never;
  pageName?: never;
}

export function hasSubItems<T extends object>(x: SidebarItemConfig<T>): x is SidebarItemWithSubItemsConfig<T> {
  return !('pageNameSuffix' in x) && !('pageName' in x);
}
