<template>
  <mosaic-count-chip :count="count" :tooltip="tooltip" />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { countHasBeenAssignedTooltip } from './mosaic-count-chip';
import { mapGetters } from '@/store/map-store';

const props = defineProps<{ count: number; objectType: string }>();

const { traineeNounCapitalised, traineeNounCapitalisedAndPluralised } = mapGetters();

const tooltip = computed(() =>
  countHasBeenAssignedTooltip(
    props.objectType,
    props.count,
    traineeNounCapitalised.value(),
    traineeNounCapitalisedAndPluralised.value
  )
);
</script>
