<template>
  <div v-if="!(tooltip || disabledTooltip)">
    <div>
      <v-btn
        class="ma-0"
        :color="backgroundColor"
        :disabled="disabled"
        v-bind="$attrs"
        :active="!!backgroundColor"
        @click.stop="emit('click', $event)"
        :size="size"
      >
        <mosaic-icon :icon="icon" :color="iconColor" :size="size" />
      </v-btn>
    </div>
  </div>
  <template v-else>
    <v-tooltip location="bottom">
      <template #activator="{ props }">
        <div v-bind="props">
          <v-btn
            class="ma-0"
            :size="size"
            :color="backgroundColor"
            :disabled="disabled"
            v-bind="$attrs"
            :active="!!backgroundColor"
            @click.stop="emit('click', $event)"
          >
            <mosaic-icon :icon="icon" :color="iconColor" :size="size" />
          </v-btn>
        </div>
      </template>
      <div style="max-width: 300px" class="text-center">
        {{ disabled && disabledTooltip ? disabledTooltip : tooltip }}
      </div>
    </v-tooltip>
  </template>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    icon: string;
    disabled?: boolean;
    tooltip?: string;
    disabledTooltip?: string;
    backgroundColor?: string;
    size?: 'x-small' | 'small' | 'medium' | 'large' | 'x-large';
    iconColor?: string;
  }>(),
  {
    disabled: false,
    iconColor: 'black',
  }
);

const emit = defineEmits<{
  click: [event: unknown];
}>();
</script>
