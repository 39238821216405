<template>
  <div class="d-flex align-center">
    <div class="d-flex justify-center" :style="{ width: mode === 'card' ? 'unset' : '86px' }">
      <template v-if="mode === 'list-no-tooltip'">
        <v-chip v-if="status === 'published'" color="accent"> Published </v-chip>
        <v-chip v-if="status === 'draft'"> Draft </v-chip>
        <v-chip v-if="status === 'archived'"> Archived </v-chip>
      </template>
      <template v-else>
        <template v-if="status === 'published'">
          <div v-if="mode === 'card'">
            <v-chip color="accent"> Published </v-chip>
          </div>
          <mosaic-tooltip-chip v-else color="accent">
            <template #text><span>Published</span></template>
            <template #tooltip>{{ publishedMessage }}</template>
          </mosaic-tooltip-chip>
        </template>
        <mosaic-tooltip-chip v-if="status === 'draft'" color="secondary">
          <template #text><span>Draft</span></template>
          <template #tooltip>{{ draftMessage }}</template>
        </mosaic-tooltip-chip>
        <mosaic-tooltip-chip v-if="status === 'archived'" color="secondary">
          <template #text><span>Archived</span></template>
          <template #tooltip>{{ archivedMessage }}</template>
        </mosaic-tooltip-chip>
      </template>
    </div>
    <div v-if="status === 'published' && mode === 'card'" class="ml-2">{{ publishedMessage }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    status: string;
    objectType: string;
    mode?: 'list' | 'card' | 'list-no-tooltip';
    publishedPhrase?: string;
    draftPhrase?: string;
    archivedPhrase?: string;
    publishedTooltip?: string;
    draftTooltip?: string;
    archivedTooltip?: string;
  }>(),
  {
    mode: 'list',
    publishedPhrase: 'published',
    archivedPhrase: 'archived',
  }
);

const publishedMessage = computed(
  () =>
    props.publishedTooltip ||
    `This ${props.objectType} has been ${props.publishedPhrase} and the content cannot be edited.`
);
const draftMessage = computed(
  () =>
    props.draftTooltip ||
    `This ${props.objectType} has not been ${props.draftPhrase || props.publishedPhrase} and can be edited.`
);
const archivedMessage = computed(
  () => props.archivedTooltip || `This ${props.objectType} has been ${props.archivedPhrase}.`
);
</script>
