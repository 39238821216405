<template>
  <span>
    <v-switch
      density="compact"
      hide-details
      class="mt-0"
      :name="name"
      :label="label"
      color="primary"
      v-bind="$attrs"
      :model-value="internalValue"
      :disabled="processing"
      @update:model-value="change($event || false)"
    />
    <mosaic-error-snackbar v-model="error" :action="`update this ${objectType}`" />
  </span>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const props = defineProps<{
  name: string;
  label: string;
  modelValue: boolean;
  objectType: string;
  updateValue: (value: boolean) => Promise<void>;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const error = ref(false);
const processing = ref(false);
const internalValue = ref(props.modelValue);

async function change(value: boolean) {
  processing.value = true;
  error.value = false;
  try {
    internalValue.value = value;
    await props.updateValue(value);
    emit('update:modelValue', value);
  } catch (e) {
    console.log(e);
    internalValue.value = !value;
    error.value = true;
  }

  processing.value = false;
}
</script>
