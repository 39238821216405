<template>
  <div class="d-flex">
    <v-text-field
      prepend-icon="mdi-lock"
      :name="name"
      :label="label"
      :type="revealed ? 'text' : 'password'"
      :model-value="modelValue"
      class="mr-2"
      :error-messages="validatePassword && passwordIsCommonlyUsed ? [commonlyUsedPasswordMessage] : []"
      :rules="rules"
      validate-on="blur"
      v-bind="$attrs"
      @update:model-value="emit('update:modelValue', $event)"
      @keyup="onKeyup"
    />
    <div>
      <ndt-icon-button v-if="revealed" icon="eye-off" tooltip="Hide password" @click.prevent="revealed = false" />
      <ndt-icon-button v-else icon="eye" tooltip="Show password" @click.prevent="revealed = true" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watchEffect } from 'vue';
import type { Rule } from '../../../utils/validations';
import NdtIconButton from '../../NdtIconButton.vue';
import {
  isPasswordCommonlyUsed,
  isPasswordLongEnough,
  passwordRules,
  isPasswordStrengthEnabled,
} from '../../../utils/passwords';
import { validationsPass } from '../../../utils/validations';
import { mapState } from '@/store/map-store';
import { useApi } from '@/composables/api';

const props = withDefaults(
  defineProps<{
    label?: string;
    name?: string;
    modelValue: string;
    validatePassword?: boolean;
    revealed?: boolean;
    rules?: Rule[];
  }>(),
  {
    label: 'Password',
    name: 'password',
    validatePassword: false,
    revealed: false,
    rules: () => [],
  }
);

const emit = defineEmits(['update:modelValue', 'keyup', 'password-valid', 'passwordValid']);

const { user } = mapState();

// This text field should always be accompanied by a MosaicPasswordSecurityLink
const commonlyUsedPasswordMessage = `Password is commonly used. Click on "Password Security" below for more information.`;

const rules = props.validatePassword ? [...passwordRules(user.value), ...props.rules] : props.rules;

const revealed = ref(props.revealed);
const passwordIsCommonlyUsed = ref(false);
const passwordIsCommonlyUsedBusy = ref(false);

const passwordValid = computed(() => {
  return validationsPass(rules, props.modelValue) && !passwordIsCommonlyUsedBusy.value && !passwordIsCommonlyUsed.value;
});
watchEffect(() => emit('passwordValid', passwordValid));

function onKeyup(event: Event) {
  emit('keyup', event);
  if (props.validatePassword) {
    checkWhetherPasswordIsCommonlyUsed();
  }
}

const api = useApi();
async function checkWhetherPasswordIsCommonlyUsed() {
  passwordIsCommonlyUsed.value = false;
  if (!isPasswordStrengthEnabled(user.value) || !isPasswordLongEnough(props.modelValue)) return;
  passwordIsCommonlyUsedBusy.value = true;
  passwordIsCommonlyUsed.value = await isPasswordCommonlyUsed(api, props.modelValue);
  passwordIsCommonlyUsedBusy.value = false;
}
</script>
