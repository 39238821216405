<template>
  <div>
    <legacy-mosaic-ordered-list
      :items="items"
      :hide-add="hideAdd"
      :object-type="objectType"
      :icon="icon"
      :move-item="moveItem"
      :move-disabled-message="moveDisabledMessage"
      :trigger-background-load="internalTriggerBackgroundLoad"
      :edit-item="retitleItem"
      :edit-item-dialog-opened="retitleItemDialogOpened"
      :delete-url-stem="deleteUrlStem"
      :can-edit-item="canRetitleItem"
      :hide-actions="hideActions"
      @update:trigger-background-load="internalTriggerBackgroundLoad = $event"
      @after-update="emit('afterUpdate')"
    >
      <template #information="{ item }">
        <mosaic-warning-icon v-if="item.warningMessage" :tooltip="item.warningMessage" />
        <slot name="information" v-bind="{ item }"></slot>
      </template>
      <template #actions="{ item }">
        <slot name="actions" v-bind="{ item }"></slot>
      </template>
      <template #edit-item-fields="{ onKeyupEnter }">
        <slot name="retitle-item-fields" :on-keyup-enter="onKeyupEnter"></slot>
      </template>
    </legacy-mosaic-ordered-list>

    <div v-if="!props.hideAdd" class="mt-2">
      <mosaic-card-heading>Add new {{ props.objectType }}</mosaic-card-heading>
      <mosaic-error-alert :error="addItemError" :action="`add ${addArticle(props.objectType)}`" />
      <div class="d-flex align-center">
        <mosaic-form ref="addItemForm" class="flex-grow-1" @submit.prevent="addAndClear">
          <div class="d-flex align-center">
            <slot name="add-item-fields"></slot>
            <input type="submit" hidden />
          </div>
        </mosaic-form>
        <mosaic-btn
          class="ml-4 mb-2"
          :disabled="!canAddItem || addItemProcessing"
          :loading="addItemProcessing"
          @click="addAndClear"
          >Add</mosaic-btn
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T extends Item">
import { ref, watch } from 'vue';
import { addArticle } from '@/utils/text';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import type { Item } from './list-item-helpers';

/* This is the legacy version, as it accepts the #edit-item-fields rather than a dialog. We should be using a dialog because the dialog
should be re-used on the item page once you've clicked into it. Potentially this component needs to support either way, but I don't think
that's the case based on current usage in the codebase. */

/*
The MosaicInlineCreationList is for lists with a limited number of items (no filtering or pagination required) and items that are simple to create
e.g. just a name or a name and date, and have an order
Longer lists or items with more complicated creation should use the MosaicFilterListPage.
*/

const props = defineProps<{
  objectType: string;
  items: T[];
  icon: string;
  hideAdd: boolean;
  canAddItem: boolean;
  canRetitleItem: boolean;
  addItem: () => Promise<void>;
  retitleItemDialogOpened: (item: T) => void;
  retitleItem: (id: number) => Promise<void>;
  // This page should always have moveItem as intended for ordered lists. However, this page is currently being used by
  // the Frameworks and Module Pages, which should really be filterLists
  moveItem?: (direction: 'up' | 'down', item: T) => Promise<void>;
  moveDisabledMessage?: string;
  deleteUrlStem: string;
  triggerBackgroundLoad?: boolean;
  hideActions?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:triggerBackgroundLoad', triggerBackgroundLoad: boolean): void;
  (e: 'afterUpdate'): void;
}>();

//Add item
const internalTriggerBackgroundLoad = ref(false);
watch(
  () => props.triggerBackgroundLoad,
  x => (internalTriggerBackgroundLoad.value = !!x)
);
watch(internalTriggerBackgroundLoad, x => {
  emit('update:triggerBackgroundLoad', x);
});

const addItemForm = ref<HTMLFormElement | null>(null);
async function internalAddItem() {
  await props.addItem();
  internalTriggerBackgroundLoad.value = true;
  emit('afterUpdate');
}

const { action: add, processing: addItemProcessing, error: addItemError } = withProcessingAndError(internalAddItem);
const addAndClear: () => Promise<void> = async () => {
  if (!props.canAddItem || addItemProcessing.value) return;
  const success = await add();
  if (success) addItemForm.value?.reset();
};
</script>
