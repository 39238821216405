<template>
  <mosaic-text-field
    :model-value="modelValue"
    label="Email"
    placeholder="Enter email"
    :rules="[isValidEmail]"
    required
    v-bind="$attrs"
    @update:model-value="emit('update:model-value', $event)"
    @keyup="$emit('keyup', $event)"
  />
</template>

<script setup lang="ts">
import { isValidEmail } from '@/utils/validations';

defineProps<{
  modelValue: string;
}>();

const emit = defineEmits<{
  'update:model-value': [v: string];
  keyup: [e: KeyboardEvent];
}>();
</script>
