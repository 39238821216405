<template>
  <div
    class="d-flex align-center"
    :class="{
      'vertical-flex-spacing-small': smallScreen,
      'horizontal-flex-spacing-small': !smallScreen,
      'flex-wrap': smallScreen,
    }"
    style="margin-top: -12px"
  >
    <span class="pt-4">Show {{ objectTypePlural }} between</span>
    <div class="d-flex align-center">
      <mosaic-date-picker
        :date="startDate"
        @update:date="emit('update:startDate', $event)"
        density="compact"
        placeholder="start date"
        hide-details
        active-icon-colour
      />
      <span class="pt-4">and</span>
    </div>
    <div class="d-flex align-center">
      <mosaic-date-picker
        :date="endDate"
        @update:date="emit('update:endDate', $event)"
        density="compact"
        placeholder="end date"
        hide-details
        active-icon-colour
      />
      <div style="margin-bottom: -20px">
        <mosaic-icon-btn name="clear-dates" icon="close" tooltip="Clear dates" @click.prevent="clearFilters()" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  objectTypePlural: string;
  startDate: string | null;
  endDate: string | null;
}>();

const emit = defineEmits<{
  'update:startDate': [date: string | null];
  'update:endDate': [date: string | null];
}>();

function clearFilters() {
  emit('update:startDate', null);
  emit('update:endDate', null);
}
</script>
