<template>
  <div class="d-flex mb-4">
    <div class="pr-4 mt-6">
      <mosaic-icon :icon="prependIcon" />
    </div>
    <div class="flex-grow-1">
      <div class="pb-1 text-color-default">{{ label }}</div>
      <div class="text-body-1" v-if="readonly && !modelValue">Not recorded</div>
      <mosaic-quill
        v-else
        :contents="modelValue"
        :read-only="readonly"
        read-only-border
        @update:contents="emit('update:modelValue', $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import MosaicQuill from '@/components/quill/MosaicQuill.vue';

withDefaults(
  defineProps<{
    modelValue?: string;
    label: string;
    name: string;
    readonly?: boolean;
    prependIcon?: string;
  }>(),
  {
    prependIcon: 'text',
    readonly: false,
  }
);

const emit = defineEmits<{
  'update:modelValue': [modelValue: string];
}>();
</script>
