<template>
  <div v-if="breadcrumbs && breadcrumbs.length > 0">
    <div
      style="position: fixed; z-index: 4; max-width: 100vw; background: white"
      :class="{ 'small-screen': smallScreen }"
      class="breadcrumbs"
    >
      <v-breadcrumbs
        :items="breadcrumbItems"
        class="px-0"
        :class="{ 'pt-0': !smallScreen }"
        color="primary"
        density="compact"
      >
        <template #divider>
          <v-icon style="margin-left: -4px; margin-right: -4px">mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>
    <div :style="{ height: '40px' }" />
  </div>
</template>

<script>
import { findInstitutionLevelHomePage } from '@/router/guard-methods';
import { findStudentLevelHomePage } from '@/utils/navigation';
import { mapState } from 'vuex';

export default {
  name: 'MosaicBreadcrumbs',
  props: {
    breadcrumbs: {
      type: Array,
    },
  },
  computed: {
    ...mapState([
      'user',
      'selectedInstitution',
      'selectedCohort',
      'selectedStudent',
      'selectedSchool',
      'userStaff',
      'userStudent',
    ]),
    breadcrumbItems() {
      if (!this.user) return [];
      let breadcrumbs = [...this.breadcrumbs];
      const student = this.userStudent || this.user.student;
      if (student) {
        breadcrumbs = [
          {
            text: 'Home',
            to: {
              name: 'StudentHomePage',
              params: { studentId: student.id },
            },
          },
          ...breadcrumbs,
        ];
      } else if (this.userStaff) {
        const pagesNotInHierarchy = ['UserProfilePage', 'TutorStaffFilesListPage', 'TutorStaffTrainingPage'];
        if (pagesNotInHierarchy.includes(this.$route.name)) {
          breadcrumbs = [
            {
              text: this.selectedInstitution.name,
              to: {
                name: 'TutorHomePage',
                params: { staffId: this.userStaff.id },
              },
            },
            ...breadcrumbs,
          ];
        } else {
          if (this.selectedStudent) {
            const to = findStudentLevelHomePage(
              this.user.staff.find(st => st.institution.id === this.selectedStudent.institution_id),
              this.selectedStudent
            );
            breadcrumbs = [
              {
                text: this.selectedStudent.name || this.selectedStudent.email,
                to,
                disabled: to.name === this.$route.name,
              },
              ...breadcrumbs,
            ];
          }
          if (this.selectedSchool && this.$route.name !== 'TutorEctApproveRegistrationPage') {
            const to = {
              name: 'TutorSchoolStudentsPage',
              params: {
                schoolId: this.selectedSchool.id,
              },
            };
            breadcrumbs = [
              {
                text: this.selectedSchool?.displayName,
                to,
                disabled: to.name === this.$route.name,
              },
              ...breadcrumbs,
            ];

            if (this.userStaffHasPermission('schools.edit')) {
              breadcrumbs = [
                {
                  text: 'Schools',
                  to: {
                    name: 'TutorSchoolsListPage',
                    params: {
                      institutionId: this.selectedInstitution.id,
                    },
                  },
                },
                ...breadcrumbs,
              ];
            }
          }

          if (this.selectedCohort && this.userStaffHasPermissionForSelectedCohort('Admin') && !this.selectedSchool) {
            const to = {
              name: 'TutorStudentListPage',
              params: {
                cohortId: this.selectedCohort.id,
              },
            };
            breadcrumbs = [
              {
                text: this.selectedCohort.name,
                to,
                disabled: to.name === this.$route.name,
              },
              ...breadcrumbs,
            ];
          }

          if (this.selectedInstitution) {
            const to = findInstitutionLevelHomePage(
              this.user.staff.find(s => s.institution.id === this.selectedInstitution.id)
            );
            if (to) {
              breadcrumbs = [
                {
                  text: this.selectedInstitution.name,
                  to,
                  disabled: to.name === this.$route.name,
                },
                ...breadcrumbs,
              ];
            }
          }
        }
      } else if (this.user.isAdmin) {
        const to = {
          name: 'AdminPage',
        };
        breadcrumbs = [
          {
            text: 'Home',
            to,
            disabled: to.name === this.$route.name,
          },
          ...breadcrumbs,
        ];
      }

      const breadcrumbLimit = this.smallScreen ? 2 : 4;
      if (breadcrumbs.length > breadcrumbLimit) {
        breadcrumbs = [
          {
            text: '...',
            to: breadcrumbs[breadcrumbs.length - (breadcrumbLimit + 1)].to,
          },
          ...breadcrumbs.slice(breadcrumbs.length - breadcrumbLimit),
        ];
      }

      const lastIndex = breadcrumbs.length - 1;
      const maxLength = this.smallScreen ? 25 : 30;
      breadcrumbs = breadcrumbs.map((b, index) => {
        let text = b.text || '';
        if (text.length > maxLength) {
          text = `${text.slice(0, maxLength)}...`;
        }
        if (index === lastIndex) {
          return {
            ...b,
            title: text,
            disabled: true,
            to: {},
            text: undefined,
          };
        } else {
          return {
            ...b,
            title: text,
            exact: true,
            text: undefined,
          };
        }
      });

      return breadcrumbs;
    },
  },
  watch: {
    breadcrumbItems(x) {
      if (x?.length) {
        document.title = `Mosaic - ${x.map(x => x.title).join(' - ')}`;
      }
    },
  },
};
</script>

<style scoped>
.breadcrumbs {
  margin-top: -20px;
  padding-top: 20px;
  width: 100%;
  margin-left: -5px;
  padding-left: 5px;
}
</style>

<style>
.small-screen {
  .v-breadcrumbs-item {
    font-size: 12px !important;
  }
  .v-breadcrumbs-divider {
    font-size: 12px !important;
  }
}
</style>
