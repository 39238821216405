<template>
  <div class="d-flex justify-space-between align-center py-4">
    <div style="width: 200px" class="pl-3">
      <slot v-if="modelValue === 'preview'" name="preview-as"></slot>
    </div>
    <v-btn-toggle v-model="configurePreview" class="pr-2" color="accent" variant="outlined" divided mandatory>
      <v-btn class="px-6" value="configure">
        Configure
        <v-icon class="pl-2" dark>mdi-pencil</v-icon>
      </v-btn>
      <v-btn class="px-6" value="preview">
        Preview
        <v-icon class="pl-2" dark>mdi-eye</v-icon>
      </v-btn>
    </v-btn-toggle>
    <div style="width: 200px" class="pr-3"></div>
  </div>

  <slot v-if="configurePreview === 'configure' || !configurePreview" name="configure"></slot>
  <slot v-if="configurePreview === 'preview'" name="preview"></slot>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import type { ConfigurePreview } from './configure-preview';

const props = defineProps<{
  modelValue?: ConfigurePreview;
}>();

const emit = defineEmits<{
  'update:modelValue': [value: ConfigurePreview];
}>();

const configurePreview = ref<ConfigurePreview>(props.modelValue || 'configure');
watch(
  () => props.modelValue,
  v => (configurePreview.value = v || null)
);
watch(configurePreview, cp => emit('update:modelValue', cp));
</script>
