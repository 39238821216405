<template>
  <mosaic-icon-btn
    v-bind="$attrs"
    icon="mdi-delete"
    :tooltip="disabled && disabledTooltip ? disabledTooltip : `Delete ${props.objectType}`"
    :hidden="hidden"
    :disabled="disabled"
    v-on="$attrs"
  />
</template>

<script setup lang="ts">
const props = defineProps<{
  objectType: string;
  hidden?: boolean;
  disabled?: boolean;
  disabledTooltip?: string;
}>();
</script>
