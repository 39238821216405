<template>
  <mosaic-info-icon color="error" icon="alert-outline" :tooltip="tooltip" />
</template>

<script>
export default {
  name: 'MosaicErrorIcon',
  props: {
    tooltip: {
      required: true,
      type: String,
    },
  },
};
</script>
