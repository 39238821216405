import type { SidebarItemConfig } from './common';
import { icons } from '@/utils/icons';

export function schoolSidebarConfig(
  traineeNounCapitalisedAndPluralised: string
): SidebarItemConfig<SchoolSidebarConfig>[] {
  return [
    {
      icon: icons.school,
      title: 'Details',
      pageName: 'TutorSchoolEditPage',
    },
    {
      icon: icons.student,
      title: traineeNounCapitalisedAndPluralised,
      pageName: 'TutorSchoolStudentsPage',
      children: ['TutorEctRegistrationPage', 'TutorEctReviewRegistrationPage'],
    },
    {
      icon: icons.schoolStaff,
      title: 'Instructors',
      pageName: 'TutorSchoolStaffPage',
    },
  ];
}

export interface SchoolSidebarConfig {
  icon: string;
  title: string;
  pageName: string;
  children?: string[];
}
