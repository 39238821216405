<template>
  <div class="d-flex justify-space-between align-center" :class="{ 'mt-4': pageCount > 1 && includeTopMargin }">
    <div style="width: 100px"></div>
    <v-pagination
      v-if="pageCount > 1"
      v-model="page"
      :length="pageCount"
      :total-visible="smallScreen ? 2 : 9"
      :density="smallScreen || compact ? 'compact' : 'default'"
      :size="smallScreen ? 'small' : 'default'"
      class="flex-shrink-1"
    ></v-pagination>
    <div
      v-if="showPageSizeOptions && total > this.pageSizes[0]"
      style="width: 100px"
      class="d-flex align-end flex-shrink-0"
      :class="{ 'flex-column': smallScreen }"
    >
      <span class="pr-2">Show</span>
      <v-select
        :items="pageSizes"
        :model-value="internalPageSize"
        hide-details
        density="compact"
        @update:model-value="internalPageSizeChanged"
      />
    </div>
    <div v-else style="width: 100px" />
  </div>
</template>

<script>
import { syncQueryParamsMixin } from '@/mixins/query-mixins';
import { useQueryStore } from '@/stores/query';

export default {
  name: 'MosaicPagination',
  mixins: [
    syncQueryParamsMixin(
      {
        page: { query: 'page', type: 'integer' },
        internalPageSize: { query: 'pageSize', type: 'integer' },
      },
      'mosaicKey',
      'syncToQuery'
    ),
  ],
  props: {
    modelValue: { type: Number, required: true },
    total: { type: Number, required: true },
    pageSize: { type: Number, default: 10 },
    showPageSizeOptions: { type: Boolean, default: true },
    pageSizesOptions: { type: Array },
    mosaicKey: { type: String, default: '' },
    includeTopMargin: { type: Boolean, default: true },
    syncToQuery: { type: Boolean, default: true },
    compact: { type: Boolean },
  },
  emits: ['update:pageSize', 'update:modelValue'],
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  data() {
    return {
      pageSizes: this.pageSizesOptions
        ? [...this.pageSizesOptions]
        : [this.pageSize, ...[5, 10, 25, 50].filter(size => size !== this.pageSize).sort((a, b) => a - b)],
      page: this.modelValue,
      internalPageSize: this.pageSize,
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.pageSize);
    },
  },
  watch: {
    modelValue(x) {
      this.page = x;
    },
    page(x) {
      this.$emit('update:modelValue', x);
    },
    pageSize(x) {
      this.internalPageSize = x;
    },
    internalPageSize(pageSize) {
      this.$emit('update:pageSize', pageSize);
      const newPageCount = Math.ceil(this.total / pageSize);
      if (this.page > newPageCount) {
        this.page = newPageCount;
      }
    },
  },
  methods: {
    internalPageSizeChanged(newPageSize) {
      // Only automatically change the page to preserve the current view when the change is user initiated
      const firstItemIndex = (this.page - 1) * this.internalPageSize;
      this.page = Math.floor(firstItemIndex / newPageSize) + 1;
      this.internalPageSize = newPageSize;
    },
  },
};
</script>
