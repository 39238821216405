<template>
  <div>
    <!-- All props passed, but not defined in the props section are available as $attrs -->
    <v-textarea
      v-if="!readonly"
      :label="label"
      :name="name"
      :class="prependIcon ? '' : noIcon ? '' : 'pl-8'"
      :model-value="modelValue"
      auto-grow
      :autofocus="autofocus"
      color="primary"
      :rows="rows"
      :hide-details="hideDetails"
      :rules="allRules"
      :variant="variant"
      :density="dense ? 'compact' : 'default'"
      @update:model-value="$emit('update:modelValue', $event)"
      @keyup="$emit('keyup', $event)"
      @update:focused="focused = $event"
      ref="VTextArea"
    >
      <template #prepend v-if="prependIcon">
        <mosaic-icon :color="focused ? 'primary' : ''" :icon="prependIcon" />
      </template>
    </v-textarea>

    <div v-else class="d-flex align-center" :class="{ 'mb-4': !hideDetails }" :data-test-name="name">
      <mosaic-icon v-if="prependIcon" class="mt-5" :icon="prependIcon" />
      <div v-else :class="{ 'pl-6': !noIcon }"></div>
      <div class="pl-4">
        <div class="text-caption">{{ label }}</div>
        <div class="text-body-1">{{ modelValue || 'Not recorded' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { requiredText } from '../../../utils/validations';
export default {
  name: 'MosaicTextArea',
  expose: ['focus'],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      required: false,
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    rows: {
      type: String,
      default: '4',
    },
    variant: {
      type: String,
      default: 'underlined',
    },
    // eslint-disable-next-line vue/require-prop-types
    modelValue: {
      required: true,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'keyup'],
  data: () => ({
    requiredText,
    focused: false,
  }),
  computed: {
    allRules() {
      return this.required ? [...this.rules, this.requiredText] : this.rules;
    },
  },
  methods: {
    focus() {
      this.$refs.VTextArea.focus();
    },
  },
};
</script>
