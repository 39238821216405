<template>
  <div style="visibility: hidden"></div>
</template>

<script>
import { syncQueryParamsMixin } from '@/mixins/query-mixins';
import { useQueryStore } from '@/stores/query';

export default {
  props: {
    showExtraFilters: { type: Boolean, required: true },
  },
  emits: ['update:tab', 'update:showExtraFilters'],
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  data() {
    return {
      internalShow: this.showExtraFilters,
    };
  },
  created() {
    this.$emit('update:tab', this.internalShow);
  },
  mixins: [
    syncQueryParamsMixin({
      internalShow: { query: 'extraFilters', type: 'boolean' },
    }),
  ],
  watch: {
    showExtraFilters(x) {
      this.internalShow = x;
    },
    internalShow(x) {
      this.$emit('update:showExtraFilters', x);
    },
  },
};
</script>
