<template>
  <mosaic-alert type="error" class="my-2" v-if="error || !!overrideErrorMessage">{{ message }}</mosaic-alert>
</template>

<script setup lang="ts">
import { isString } from 'lodash';
import { computed } from 'vue';

const props = defineProps<{
  action?: string;
  error?: boolean | string;
  overrideErrorMessage?: string;
}>();

const message = computed(() => {
  if (isString(props.error)) return props.error;
  return props.overrideErrorMessage || `Sorry, cannot ${props.action} at the moment`;
});
</script>
