<template>
  <v-card>
    <v-card-text class="pa-5" :class="{ [paddingLeftClass]: !!pl || pl === 0, [paddingRightClass]: !!pr || pr === 0 }">
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  pl?: number;
  pr?: number;
}>();

const paddingLeftClass = computed(() => `pl-${props.pl}`);
const paddingRightClass = computed(() => `pr-${props.pr}`);
</script>
