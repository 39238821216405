<template>
  <mosaic-completed-chip :name="name" :completed="completed" v-bind="$attrs" :tooltip="tooltip" />
</template>

<script setup lang="ts">
import MosaicCompletedChip from './MosaicCompletedChip.vue';
import { mapGetters, mapActions, type RoleWithStudent } from '@/store/map-store';
import { computed } from 'vue';

const { loadRoles } = mapActions();
loadRoles();
const { rolesWithStudent } = mapGetters();

const props = defineProps<{
  completed: boolean;
  roleId: number | 'student';
  roleScopedTooltip?: (role: RoleWithStudent) => string;
}>();

const { traineeNounCapitalised, traineeNounCapitalisedAndPluralised } = mapGetters();

const roleMap = computed(() => {
  if (!rolesWithStudent.value.length) return null;
  const roleMap: Record<number | string, RoleWithStudent> = {};
  for (const role of rolesWithStudent.value) {
    roleMap[role.id] = role;
  }
  return roleMap;
});

const studentRole: RoleWithStudent = {
  id: 'student',
  name: traineeNounCapitalised.value(),
  pluralisedName: traineeNounCapitalisedAndPluralised.value,
};

const role = computed(() => {
  if (!roleMap.value) return null;
  return props.roleId === 'student' ? studentRole : roleMap.value[props.roleId];
});

const name = computed(() => {
  if (!role.value || !roleMap.value) return '';
  return role.value.name;
});

const tooltip = computed(() => {
  if (!role.value || !props.roleScopedTooltip) return '';
  return props.roleScopedTooltip(role.value);
});
</script>
