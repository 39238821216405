<template>
  <div v-if="hidden" :style="{ width: '48px' }"></div>
  <div v-else-if="!(tooltip || disabledTooltip)">
    <div>
      <v-btn
        :class="`ma-0 ${activeButton ? 'active-button' : ''}`"
        icon
        variant="text"
        :disabled="disabled"
        v-bind="$attrs"
        @click.stop="emit('click', $event)"
        :size="size"
      >
        <v-badge :color="backgroundColor" :content="numberItems">
          <mosaic-icon :icon="icon" :color="iconColor" :size="size" />
        </v-badge>
      </v-btn>
    </div>
  </div>
  <template v-else>
    <v-tooltip location="bottom">
      <template #activator="{ props }">
        <div v-bind="props">
          <div v-bind="$attrs" @click.stop="emit('click', $event)">
            <v-btn
              :class="`ma-0 ${activeButton ? 'active-button' : ''}`"
              :size="size"
              icon
              variant="text"
              :disabled="disabled"
            >
              <v-badge :color="backgroundColor" class="text-xs" location="top right" :content="numberItems">
                <mosaic-icon :icon="icon" :color="iconColor" :size="size" class="mx-2" />
              </v-badge>
            </v-btn>
          </div>
        </div>
      </template>
      <div style="max-width: 300px" class="text-center">
        {{ disabled && disabledTooltip ? disabledTooltip : tooltip }}
      </div>
    </v-tooltip>
  </template>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    icon: string;
    disabled?: boolean;
    tooltip?: string;
    disabledTooltip?: string;
    backgroundColor?: string;
    iconColor?: string;
    size?: 'x-small' | 'small' | 'medium' | 'large' | 'x-large';
    hidden?: boolean;
    numberItems?: number | string;
    activeButton?: boolean;
  }>(),
  {
    disabled: false,
    hidden: false,
  }
);

const emit = defineEmits<{
  click: [event: unknown];
}>();
</script>

<style scoped>
.active-button {
  background-color: rgba(255, 255, 255, 0.15);
}
</style>
