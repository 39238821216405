<template>
  <v-alert type="info" variant="outlined" class="mb-2">
    How to set a strong password:
    <ul>
      <li>Make your password at least 12 characters</li>
      <li>
        Generate your password using a password manager (and save it there) or a technique like
        <a
          href="https://www.ncsc.gov.uk/collection/top-tips-for-staying-secure-online/three-random-words"
          target="_blank"
          >three random words</a
        >
      </li>
      <li>Don't use personal or school information in your password</li>
    </ul>
  </v-alert>
</template>
