<template>
  <mosaic-save-dialog
    v-model:active="nasbttModulesAccountLinkDialog.active"
    :title="`Link your Mosaic and ${accountPhrase}s`"
    :object-type="
      waitingForConfirmation
        ? 'NASBTT module'
        : nasbttModulesAccountLinkDialog.publicationCode
        ? 'NASBTT Modules account and module'
        : 'account'
    "
    :action="
      waitingForConfirmation ? 'open module' : nasbttModulesAccountLinkDialog.publicationCode ? 'link and open' : 'link'
    "
    :can-save="waitingForConfirmation ? true : !!code"
    :save="waitingForConfirmation ? () => afterLinking(true) : save"
    :save-errors="{
      account_not_found: `Sorry, this ${accountPhrase} wasn\'t found. Please check your login code and try again.`,
    }"
    :close-text="waitingForConfirmation ? 'Close' : undefined"
  >
    <v-skeleton-loader v-if="automaticallyLinkProcessing || leaving" type="paragraph" />
    <div v-else-if="waitingForConfirmation">
      Mosaic has successfully matched your Mosaic and {{ accountPhrase }} details and linked your accounts. You can now
      open NASBTT Modules from within Mosaic.
    </div>
    <div v-else>
      <div class="mb-2">
        Your Mosaic and {{ accountPhrase }}s aren't currently linked. Go to your
        <a class="text-link" target="_blank" :href="`${nasbttModulesFrontendPath}/drcode/`">{{ accountPhrase }}</a>
        to get your login code and enter it below.
      </div>
      <mosaic-text-field name="code" label="Login code" v-model="code" prepend-icon="link-box-variant-outline" />
      <div>If you don't have a {{ accountPhrase }} then contact your course leads.</div>
    </div>
  </mosaic-save-dialog>
</template>

<script setup lang="ts">
import { useApi } from '@/composables/api';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import { mapActions } from '@/store/map-store';
import { useNasbttModulesStore } from '@/stores/nasbtt-modules';
import { watch, ref, computed } from 'vue';
import { nasbttModulesFrontendPath } from '@/utils/config';
import { useUserStore } from '@/stores/user';
import { useStaffStore } from '@/stores/staff';

const api = useApi();
const { refreshUser } = mapActions();
const { user } = useUserStore();
const { userStaff } = useStaffStore();
const {
  nasbttModulesAccountLinkDialog,
  actions: { openStaffNasbttModule, openStudentNasbttModule },
} = useNasbttModulesStore();

const accountPhrase = computed(() => `NASBTT ${user.value?.student ? 'Learn' : 'Mentor Development'} account`);

const leaving = ref(false);
const waitingForConfirmation = ref(false);
const { action: automaticallyLink, processing: automaticallyLinkProcessing } = withProcessingAndError(async () => {
  leaving.value = false;
  waitingForConfirmation.value = false;
  const r = await api.post<unknown, { success: boolean }>(`/nasbtt-modules/accounts/link-automatically`, {});
  if (r.data.success) {
    if (nasbttModulesAccountLinkDialog.value.publicationCode) {
      await refreshUser();
      waitingForConfirmation.value = true;
    } else {
      leaving.value = true;
      return await afterLinking();
    }
  }
});

watch(
  () => nasbttModulesAccountLinkDialog.value.active,
  active => {
    if (active) automaticallyLink();
  }
);

const code = ref('');
async function save() {
  await api.post(`nasbtt-modules/accounts/link`, { code: code.value });
  return await afterLinking();
}

async function afterLinking(userAlreadyRefreshed = false) {
  if (!userAlreadyRefreshed) await refreshUser();
  const publicationCode = nasbttModulesAccountLinkDialog.value.publicationCode;
  if (publicationCode) {
    if (user.value.student) {
      return openStudentNasbttModule(user.value.student.id, publicationCode);
    } else {
      return openStaffNasbttModule(userStaff.value.id, publicationCode);
    }
  } else {
    nasbttModulesAccountLinkDialog.value = { active: false, publicationCode: null };
  }
}
</script>
