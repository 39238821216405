<template>
  <span :class="{ opacity: !color }">
    <template v-if="isMosaicIcon">
      <component :is="icon.endsWith('-icon') ? icon : icon + '-icon'" :color="color" :size="size" />
    </template>
    <v-icon v-else :size="size" :color="color" style="width: 24px">
      {{ icon.startsWith('mdi-') ? icon : `mdi-${icon}` }} {{ spin ? 'mdi-spin' : '' }}
    </v-icon>
  </span>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  icon: string;
  color?: string;
  size?: 'x-small' | 'small' | 'medium' | 'large' | 'x-large';
  // Only implemented for v-icon
  spin?: boolean;
}>();

const isMosaicIcon = computed(() => props.icon.startsWith('mosaic-'));
</script>

<style scoped>
.opacity {
  opacity: var(--v-medium-emphasis-opacity);
}
</style>
