<template>
  <mosaic-card>
    <slot></slot>
    <mosaic-save-buttons v-bind="props" @update:trigger-save="emit('update:triggerSave', $event)">
      <template #beside-buttons v-if="$slots['beside-buttons']">
        <slot name="beside-buttons"></slot>
      </template>

      <template #buttons v-if="$slots.buttons">
        <slot name="buttons"></slot>
      </template>
    </mosaic-save-buttons>
  </mosaic-card>
</template>

<script setup lang="ts">
import type { RouteLocationNamedRaw } from 'vue-router';

const props = withDefaults(
  defineProps<{
    save: () => Promise<unknown | string>;
    objectType: string;
    returnTo: RouteLocationNamedRaw;
    objectTypeIsPlural?: boolean;
    canSave: boolean;
    valid?: boolean;
    hideReturn?: boolean;
    triggerSave?: boolean;
    isCreating?: boolean;
    readonly?: boolean;
    returnIsPrimary?: boolean;
    preview?: boolean;
  }>(),
  {
    valid: true,
  }
);

const emit = defineEmits<{
  (e: 'update:triggerSave', triggerSave: boolean): void;
}>();
</script>
