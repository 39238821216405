<template>
  <v-avatar
    image="https://mosaic-production-1.s3.eu-west-1.amazonaws.com/public/bud_icon.PNG"
    :style="{ height, width }"
  >
  </v-avatar>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    size?: 'default' | 'large';
  }>(),
  {
    size: 'default',
  }
);

const height = computed(() => (props.size == 'large' ? 'default' : '24px'));
const width = computed(() => (props.size == 'large' ? 'default' : '24px'));
</script>
