<template>
  <span>
    <v-tooltip location="top">
      <template #activator="{ props }">
        <span v-bind="props">
          <v-chip :color="color" @[clickHandler].stop="launchHelp" :variant="variant">
            <slot name="text"></slot>
            <v-icon v-if="helpSiteUrl" class="pb-2" size="small" style="margin-right: -8px">mdi-help-circle</v-icon>
          </v-chip>
        </span>
      </template>
      <span><slot name="tooltip"></slot></span>
    </v-tooltip>
  </span>
</template>

<script>
export default {
  name: 'MosaicTooltipChip',
  props: {
    color: {
      default: 'primary',
      type: String,
    },
    helpSiteUrl: {
      type: String,
      default: null,
    },
    variant: {
      default: 'tonal',
      type: String,
    },
  },
  computed: {
    clickHandler() {
      return this.helpSiteUrl ? 'click' : null;
    },
  },
  methods: {
    launchHelp() {
      window.open(this.helpSiteUrl, '_blank');
    },
  },
};
</script>
