<template>
  <mosaic-icon-btn
    v-if="modelValue"
    icon="link"
    background-color="accent"
    tooltip="Edit Evidence"
    @click.prevent="emit('clickEdit')"
    :disabled="processing"
    :loading="processing"
  />
  <mosaic-icon-btn v-else icon="link-off" tooltip="Link as Evidence" @click.prevent="emit('clickCreate')" />
</template>

<script setup lang="ts">
type Evidence = {
  id: number;
};

defineProps<{
  modelValue: Evidence | null | undefined | number;
  processing?: boolean;
}>();

const emit = defineEmits<{
  (e: 'clickEdit'): void;
  (e: 'clickCreate'): void;
}>();
</script>
