<template>
  <div>
    <mosaic-icon-btn-number
      @click="toggleCard($event as Event)"
      icon="near-me"
      :background-color="loadError ? 'error' : 'accent'"
      :tooltip="
        busy
          ? 'Loading active Targets'
          : loadError
          ? 'Sorry, cannot load the active Targets'
          : activeTargets?.length ?? 0 !== 0
          ? 'View active Targets'
          : `No active Targets`
      "
      :icon-color="iconColor"
      :color="'white'"
      :active-button="cardOpen"
      :number-items="busy ? '...' : loadError ? '!' : activeTargets.length"
    />
    <mosaic-draggable-card
      v-if="cardOpen"
      :close-card="closeCard"
      :title="selectedStudent ? `Active Targets for ${selectedStudent.name}` : 'Your Active Targets'"
      :x="x"
      :y="y"
    >
      <div class="mt-4 mb-4" v-if="loadError && !busy">
        <mosaic-load-error object-type="active Targets" :viewing-student="!!selectedStudent" @retry="loadTargets" />
        .
      </div>

      <v-skeleton-loader v-if="busy && !loadError" type="list-item-avatar-two-line@3"></v-skeleton-loader>

      <targets-list-display
        v-if="!busy && !loadError && activeTargets.length > 0"
        :click-target="closeCardOnTargetClick"
        :targets="activeTargets"
        :target-click-to="targetClickTo"
        :page-sizes-options="getPageOptions()"
      />
      <div v-if="!busy && !loadError && (!activeTargets || activeTargets?.length === 0)">
        There are no active Targets set.
      </div>
    </mosaic-draggable-card>
  </div>
</template>

<script setup lang="ts">
import TargetsListDisplay from './TargetsListDisplay.vue';
import MosaicDraggableCard from './library/mosaic-card/MosaicDraggableCard.vue';
import type { TargetResponse } from '@/types/responses';
import { ref } from 'vue';

interface SelectedStudent {
  name: string;
}

// Setup
const props = defineProps<{
  activeTargets: TargetResponse[];
  studentId: number;
  selectedStudent: SelectedStudent | null;
  iconColor?: string;
  loadError?: boolean;
  loadTargets: () => void;
  busy: boolean;
}>();

const cardOpen = ref(false);
const y = ref(0);
const x = ref(0);

const toggleCard = (e: Event): void => {
  if (e instanceof MouseEvent) {
    x.value = e.x;
    y.value = e.y;
    cardOpen.value = !cardOpen.value;
  }
};

const closeCard = () => {
  cardOpen.value = false;
};

const closeCardOnTargetClick = () => {
  if (window.innerWidth < 992) cardOpen.value = false;
};

const targetClickTo = (targetId: number) => {
  return {
    name: 'TargetPage',
    params: { studentId: props.studentId, targetId },
  };
};

//avoid higher numbers for the pagination in smaller devices - avoid scroll
const getPageOptions = () => {
  const h = window.innerHeight;
  const w = window.innerWidth;

  return (h > 900 && w > 992) || w > 1100
    ? [1, 3, 5, 7]
    : (h > 900 && w < 992) || (h < 900 && h > 700)
    ? [1, 3, 5]
    : [1, 3];
};
</script>
