import type { ListIteratee, Many, ValueIteratee } from 'lodash';
import _ from 'lodash';

declare global {
  interface Array<T> {
    sortBy(sort: Many<ListIteratee<T>>, orders?: Many<boolean | 'asc' | 'desc'>): T[];
    unique(): T[];
    uniqueBy(selector: ValueIteratee<T>): T[];
    sum(): number;
  }
}

Array.prototype.unique = function () {
  function onlyUnique<T>(value: T, index: number, self: T[]) {
    return self.indexOf(value) === index;
  }
  return this.filter(onlyUnique);
};

Array.prototype.uniqueBy = function (selector) {
  return _.uniqBy(this, selector);
};

Array.prototype.sortBy = function <T>(selectors: Many<ListIteratee<T>>, orders?: Many<boolean | 'asc' | 'desc'>) {
  return _.orderBy<T>(this, selectors, orders);
};

Array.prototype.sum = function () {
  return _.sum(this);
};
