<template>
  <div>
    <!-- All props passed, but not defined in the props section are available as $attrs -->
    <v-text-field
      v-if="!readonly"
      :label="label"
      :model-value="modelValue"
      :name="name"
      color="primary"
      :class="{ 'pl-8': !prependIcon && !noIcon }"
      :style="{ width: halfWidth ? '45%' : 'unset' }"
      :density="dense ? 'compact' : 'default'"
      v-bind="$attrs"
      :validate-on="validateOn"
      :rules="allRules"
      @update:model-value="$emit('update:modelValue', $event)"
      @keyup="$emit('keyup', $event)"
      variant="underlined"
      @update:focused="focused = $event"
      ref="VTextField"
    >
      <template #prepend v-if="prependIcon">
        <mosaic-icon :color="focused ? 'primary' : undefined" :icon="prependIcon" />
      </template>
    </v-text-field>
    <!-- mb-4 will need to change if hide-details etc. are supplied -->
    <div v-else class="d-flex align-center mb-4" :data-test-name="name">
      <mosaic-icon v-if="prependIcon" class="mt-5" :icon="prependIcon" />
      <div v-else :class="{ 'pl-6': !noIcon }"></div>
      <div class="pl-4">
        <div class="text-caption">{{ label }}</div>
        <mosaic-router-link v-if="link && modelValue" class="text-body-1" :to="link">
          {{ modelValue }}</mosaic-router-link
        >
        <div v-else class="text-body-1">{{ modelValue || 'Not recorded' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { requiredText } from '../../../utils/validations';
export default {
  name: 'MosaicTextField',
  // Means the style attributes will not be applied twice
  // inheritAttrs: false,
  expose: ['focus'],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Object,
      default: () => null,
    },
    prependIcon: {
      type: String,
      required: false,
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    halfWidth: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
    // eslint-disable-next-line vue/require-prop-types
    modelValue: {
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    validateOn: {
      type: String,
      default: 'blur',
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['keyup', 'update:modelValue'],
  data: () => ({
    requiredText,
    focused: false,
  }),
  computed: {
    allRules() {
      return this.required ? [this.requiredText, ...this.rules] : this.rules;
    },
  },
  methods: {
    focus() {
      this.$refs.VTextField.focus();
    },
  },
};
</script>
