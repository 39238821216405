<template>
  <mosaic-info-icon color="warning" icon="alert-circle-outline" :tooltip="tooltip" />
</template>

<script>
export default {
  name: 'MosaicWarningIcon',
  props: {
    tooltip: {
      required: true,
      type: String,
    },
  },
};
</script>
