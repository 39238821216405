<template>
  <div>
    <v-form ref="mosaicForm" v-bind="$attrs">
      <slot />
    </v-form>
    <mosaic-snackbar v-model="snackbar.active" :color="snackbar.color" location="bottom" :message="snackbar.message" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { VForm } from 'vuetify/components';

const mosaicForm = ref<VForm | null>(null);
const snackbar = ref({
  message: '',
  color: undefined as 'error' | undefined,
  active: false,
  timeout: 3000,
});

async function validate(hideSnackbar: boolean): Promise<boolean | void> {
  if (mosaicForm.value && (await mosaicForm.value.validate()).valid) {
    return true;
  } else if (!hideSnackbar) {
    snackbar.value = {
      message: 'Please ensure all required fields are complete and valid.',
      color: 'error',
      active: true,
      timeout: 3000,
    };
  }
}

function reset() {
  if (mosaicForm.value) {
    mosaicForm.value.reset();
  }
}

defineExpose({
  validate,
  reset,
});
</script>
