<template>
  <mosaic-loading-and-error-cards
    :object-type="objectType"
    :load="load"
    :error="error"
    :trigger-background-load="triggerBackgroundLoad"
    @update:trigger-background-load="$emit('update:triggerBackgroundLoad', $event)"
  >
    <mosaic-configure-preview :model-value="modelValue" @update:model-value="$emit('update:modelValue', $event)">
      <template #configure>
        <slot name="configure"></slot>
      </template>
      <template #preview>
        <slot name="preview"></slot>
      </template>
    </mosaic-configure-preview>
  </mosaic-loading-and-error-cards>
</template>

<script setup lang="ts">
import { syncQueryParam } from '@/composables/query';
import { watch, ref } from 'vue';

import type { ConfigurePreview } from './configure-preview';

const props = defineProps<{
  modelValue: ConfigurePreview;
  objectType: string;
  load: (force?: boolean) => Promise<void>;
  error?: boolean;
  triggerBackgroundLoad?: boolean;
}>();

const emit = defineEmits(['update:triggerBackgroundLoad', 'update:modelValue']);

const configurePreview = ref(props.modelValue);

watch(
  () => props.modelValue,
  x => {
    configurePreview.value = x;
  }
);

watch(
  () => configurePreview.value,
  x => {
    emit('update:modelValue', x);
  }
);

syncQueryParam(configurePreview, 'preview', 'string');
</script>
